/* Add your custom JavaScript code */

var swiper = new Swiper('.swiper-container', {
    loop: true,
    autoplay: {
        delay: 4000,
        disableOnInteraction: false,
    }
});

if ($(".slick-nav").length) {
    $(".slick-nav").slick({
        asNavFor: ".slick-main",
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        focusOnSelect: true
    })
}

if ($(".slick-main").length) {
    $(".slick-main").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: ".slick-nav"
    });
}
if ($(".isotope").length) {
    let listItem = $(".isotope").isotope({
        itemSelector: ".post-item",
        layoutMode: "vertical"
    })
}

function updateCartCount() {
    $.ajax({
        url: "/cart/count",
        success: function (data, status) {
            $(".cart-notification").text(data.result)
        }
    })
}

function addToCart(elem, items) {
  let $self = $(elem)
  items = (typeof items !== 'undefined') ?  items : 1;
  let $url = '/cart/add/:id/' + items
  $.ajax({
    url: $url.replace(':id', $self.data('id'))
  })
    .done(function(r){
      $(document).trigger('cartUpdate')
      swal({
        title: '商品已加入購物車。',
        icon: 'success',
        text: '接下來您要？',
        buttons: {
          cancel: "繼續購物",
          confirm: {
            text: "前往結帳",
            value: 'confirm',
            closeModal: true
          }
        }
      }).then(redirect)
    })
    .fail(function(xhr, r) {
      if (parseInt(xhr.status) === 401) return window.location.href = "/user/login";
      let response = xhr.responseJSON;
      swal({
        title: 'Oops!',
        text: response.message,
        icon: 'warning',
        buttons: {
          cancel: "繼續購物",
          confirm: {
            text: "前往結帳",
            value: 'confirm',
            closeModal: true
          }
        }
      }).then(redirect)
    })
}

function redirect(confirm) {
    if (confirm) {
        window.location = "/cart"
    } else {
        return false
    }
}



$(document).on("cartUpdate", updateCartCount);
$(document).trigger("cartUpdate");